// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

const ruleNameRE = /^projects\/(.*)\/rules\/(.*)$/;

// RuleKey represents the key parts of a rule resource name.
export interface RuleKey {
  project: string;
  ruleId: string;
}

// parseRuleName parses a rule resource name into its key parts.
export function parseRuleName(name: string): RuleKey {
  const results = name.match(ruleNameRE);
  if (results === null) {
    throw new Error('invalid rule resource name: ' + name);
  }
  return {
    project: results[1],
    ruleId: results[2],
  };
}
